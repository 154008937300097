import React from "react";
import AOS from "aos";

import { BrowserRouter } from "react-router-dom"

import Navbar from "./components/_App/Navbar";
import MainBanner from "./components/MainBanner";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import AppointmentForm from "./components/AppointmentForm";
import AboutUs from "./components/AboutUs";
import Facilities from "./components/Facility";
import Footer from "./components/_App/Footer";
import GoTop from "./components/_App/GoTop";

function App() {

  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navbar />

        <MainBanner />

        <Facilities/>

        <Services />

        <Gallery />

        <AppointmentForm />

        <AboutUs />

        <Footer />

        <GoTop scrollStepInPx="100" delayInMs="10.50" />
      </BrowserRouter>
    </>
  );
}

export default App;
