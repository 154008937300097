import React from "react";

const AboutUs = () => {
  return (
    <>
      <div id="informacije" className="emergency-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="emergency-content ptb-100">
                <h2>Informacije i Obaveštenja</h2>
                <p>
                  U Hera Medicalu, briga o vama ne poznaje radno vreme. Služba patronaže 
                  je dostupna korisnicima na čitavoj teritoriji Valjeva, pružajući vam 
                  mogućnost zakazivanja usluga tokom celog dana. Naše radno vreme je od 
                  7 do 23 sata, a naše iskusno osoblje je spremno da vam pruži vrhunsku 
                  patronažnu negu, bilo da se radi o starijim osobama, hroničnim bolesnicima, 
                  pacijentima u terminalnim fazama bolesti ili onima koji se oporavljaju 
                  nakon bolničkog lečenja.
                </p>

                <p>
                  Svestni smo da je naša obaveza osigurati da se pored nas pacijent oseća 
                  sigurno, stoga smo uložili napore da na usluge koje pružamo uvek možete 
                  da računate. Pozovite ili nam pišite - tu smo da odgovorimo na sva vaša 
                  pitanja i pružimo vam potrebne informacije.
                </p>

                <ul>
                  <li className="active">
                    <a href="tel:+381668404040">
                      <i className="bx bx-phone-call"></i>
                      <span>Mobilni:</span>
                      <h3>
                        066/ 8-40-40-40
                      </h3>
                    </a>
                  </li>

                  <li className="active">
                    <a href="tel:+381143106304">
                      <i className="bx bx-phone-call"></i>
                      <span>Stabilni:</span>
                      <h3>
                        014/ 310-63-04
                      </h3>
                    </a>
                  </li>

                  <li>
                    <a href="mailto:hello@info.com">
                      <i className="bx bx-envelope"></i>
                      <span>Pošta:</span>
                      <h3>
                        office@heramedical.rs
                      </h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 pr-0">
              <div className="emergency-img"></div>
            </div>
          </div>
        </div>

        <div className="shape">
          <img src="/img/shape/shape (3).webp" alt="Shape" width="165" height="108" />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
