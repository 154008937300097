import React from "react";

import { HashLink } from "react-router-hash-link";

const MainBanner = () => {

  return (
    <>
      <div id="naslovna" className="main-banner-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="banner-text">
                  <span
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    >
                      Hera Medical
                    </span>

                    <h1
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="1200"
                    >
                      Ambulanta za Zdravstvenu Negu
                    </h1>

                    <p
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="1200"
                    >
                      Patronažna služba koja pruža usluge sveobuhvatne 
                      zdravstvene nege i podrške svojim pacijentima.
                    </p>

                    <div
                      className="banner-btn"
                      data-aos="fade-up"
                      data-aos-delay="400"
                      data-aos-duration="1200"
                    >
                      <HashLink smooth to="#rezervacije" className="default-btn">
                        Zakažite Svoj Termin
                      </HashLink>
                    </div>
                  </div>
                </div>

                <div className="first-facility-area">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6">
                      <div 
                        className="first-facility-item" 
                        data-aos="fade-in"
                        data-aos-delay="100"
                        data-aos-duration="1200"
                      >
                        <i className="flaticon-care"></i>
                        <h3>Tu Smo za Vas</h3>
                        <p>
                          Pružanje usluga vršimo kako ambulantno tako i na terenu
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                      <div 
                        className="first-facility-item" 
                        data-aos="fade-in"
                        data-aos-delay="200"
                        data-aos-duration="1200"
                      >
                        <i className="flaticon-support"></i>
                        <h3>Uskoro Otvoreni 24/7</h3>
                        <p>
                          Radimo na proširenju naših usluga kako bismo uskoro 
                          bili dostupni 24/7
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                      <div 
                        className="first-facility-item" 
                        data-aos="fade-in"
                        data-aos-delay="300"
                        data-aos-duration="1200"
                      >
                        <i className="flaticon-online-learning"></i>
                        <h3>Rezervišite Online</h3>
                        <p>
                          Lako i brzo rezervišite svoj termin iz udobnosti svog doma
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="shape">
                    <img src="/img/shape/shape (3).webp" alt="Shape" width="570" height="400" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
