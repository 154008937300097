import React from "react";

const Services = () => {
  return (
    <>
      <div id="usluge" className="about-area pb-130">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="/img/services/services.webp" alt="Services" width="570" height="604" />

                <div className="shape-1">
                  <img src="/img/services/shape (1).png" alt="Shape" width="570" height="605" />
                </div>

                <div className="shape-2">
                  <img src="/img/services/shape (2).png" alt="Shape" width="289" height="610" />
                </div>

                <div className="shape-3">
                  <img src="/img/services/shape (3).png" alt="Shape" width="185" height="185" />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span className="top-title">Usluge</span>
                <h2>Ambulanta za zdravstvenu negu pruža sledeće usluge:</h2>
                <p>
                  Fokusirana na pružanje raznolikih usluga, Hera Medical je posvećena 
                  unapređenju kvaliteta života pacijenata kroz širok spektar medicinskih 
                  usluga koje nudi.
                </p>

                <ul>
                  <li>
                    <i className="flaticon-tick"></i>
                    Sanitetski transport pacijenata
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Nega majke i bebe
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Plasiranje katetera i ispiranje bešike
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Aplikacija injekcija
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Davanje infuzija
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Merenje nivoa šećera u krvi
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Previjanje i sanacija svih vrsta rana
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Inhalacije
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Medicinska podrška javnih skupova i događaja
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
