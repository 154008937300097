import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./styles/bootstrap.min.css";
import "./styles/animate.css";
import "./styles/meanmenu.css";
import "./styles/boxicons.min.css";
import "./styles/flaticon.css";

import "swiper/css";
import "swiper/css/bundle";
import 'aos/dist/aos.css';
import "react-datepicker/dist/react-datepicker.css";

// Global Styles
import "./styles/style.css";
import "./styles/responsive.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
