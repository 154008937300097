import React, { useState, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";
import emailjs from "@emailjs/browser";
import sr from "date-fns/locale/sr-Latn"

registerLocale("sr", sr);

const AppointmentForm = () => {
  const form = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(0);
  const minDate = new Date();
  const hours = minDate.getHours();
  let minutes = minDate.getMinutes();
  let startTime;

  const sendEmail = (event) => {
    event.preventDefault();

    let time = `${Math.floor(event.currentTarget.elements.Time.value / 3600)}:${event.currentTarget.elements.Time.value % 3600 / 60}`

    if (time.slice(-2, -1) === ":") {
      time = time.slice(0, -1) + "0" + time.slice(-1);
    }

    if (time.length === 4) {
      time = "0" + time;
    }

    var templateParams = {
      Name: event.currentTarget.elements.Name.value,
      Email: event.currentTarget.elements.Email.value,
      Phone: event.currentTarget.elements.Phone.value,
      Service: event.currentTarget.elements.Service.value,
      Date: event.currentTarget.elements.Date.value,
      Time: time,
      Message: event.currentTarget.elements.Message.value
    };

    emailjs.send("service_p45mq8v", "template_5m8a8ck", templateParams, "aXo1_SG1UNONpiGat")
      .then((_) => { window.confirm("Uspešno ste zakazali vaš termin!"); });

    document.getElementById("form").reset();
  };

  startDate.setHours(0, 0, 0, 0);
  minDate.setHours(0, 0, 0, 0);

  if (minDate.getTime() !== startDate.getTime() || hours < 7) {
    startTime = "07:00";
  } else if (hours >= 23) {
    startTime = "07:00";
    startDate.setDate(startDate.getDate() + 1);
  } else {
    minutes = (Math.floor(minutes / 15) + 1) * 15;
    startTime = minutes !== 60 ? `${hours}:${minutes}` : `${hours + 1}:00`;
  }

  return (
    <>
      <div id="rezervacije" className="appointment-area jarallax ptb-50">
        <div className="container">
          <div className="appointment-here-form">
            <span className="top-title">Rezervacije</span>
            <h2>Zakažite Svoj Termin</h2>

            <form id="form" ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Name">Ime i Prezime</label>
                  <div className="form-group">
                    <input
                      id="Name"
                      name="Name"
                      type="text"
                      className="form-control"
                      placeholder="Unesite Svoje Ime i Prezime"
                      required="required"
                      autoComplete="name"
                    />
                    <i className="flaticon-account"></i>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Email">Email</label>
                  <div className="form-group">
                    <input
                      id="Email"
                      name="Email"
                      type="email"
                      className="form-control"
                      placeholder="Unesite Svoju Email Adresu"
                      required="required"
                      autoComplete="email"
                    />
                    <i className="flaticon-email"></i>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Phone">Telefon</label>
                  <div className="form-group">
                    <input
                      id="Phone"
                      name="Phone"
                      type="tel"
                      className="form-control"
                      placeholder="Unesite Svoj Broj Telefona"
                      required="required"
                      autoComplete="tel"
                    />
                    <i className="flaticon-smartphone"></i>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Service">Medicinska Usluga</label>
                  <div className="form-group">
                    <select className="form-control" id="Service" name="Service">
                      <option value="Nije izabrano">Izaberite Uslugu</option>
                      <option value="Sanitetski transport pacijenta">Sanitetski transport pacijenta</option>
                      <option value="Nega majke i bebe">Nega majke i bebe</option>
                      <option value="Plasiranje katetera i ispiranje bešike">Plasiranje katetera i ispiranje bešike</option>
                      <option value="Davanje injekcije">Davanje injekcije</option>
                      <option value="Merenje nivoa šećera u krvi">Merenje nivoa šećera u krvi</option>
                      <option value="Previjanje i sanacija rane">Previjanje i sanacija rane</option>
                      <option value="Inhalacija">Inhalacija</option>
                      <option value="Ostalo">Ostalo</option>
                    </select>
                    <i className="flaticon-heart"></i>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Date">Željeni datum</label>
                  <div className="form-group">
                    <div className="input-group date">
                      <DatePicker
                        id="Date"
                        name="Date"
                        className="form-control"
                        minDate={minDate}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        calendarStartDay={1}
                        locale="sr"
                      />
                    </div>
                    <i className="flaticon-appointment"></i>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <label htmlFor="Time">Željeno vreme</label>
                  <div className="form-group">
                    <div className="input-group time">
                      <TimePicker
                        id="Time"
                        name="Time"
                        start={startTime}
                        end="23:00"
                        step={15}
                        value={selectedTime}
                        onChange={(time) => setSelectedTime(time)}
                        format={24}
                      />
                    </div>
                    <i className="flaticon-clock"></i>
                  </div>
                </div>

                <div className="col-lg-12">
                  <label htmlFor="Message">Poruka</label>
                  <div className="form-group">
                    <textarea
                      id="Message"
                      name="Message"
                      className="form-control"
                      placeholder="Ostavite Poruku"
                      cols="30"
                      rows="8"
                    />
                    <i className="flaticon-edit"></i>
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="default-btn">
                    Pošalji
                  </button>
                </div>
              </div>
            </form>

            <div className="shape">
              <img src="/img/shape/shape (1).webp" alt="Appointment Shape" width="248" height="214" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentForm;
