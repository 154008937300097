import React from "react";
import TopHeader from "./TopHeader";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [menu, setMenu] = React.useState(true);

  const classOne = menu ? "collapse navbar-collapse " : "collapse navbar-collapse show ";
  const classTwo = menu ? "navbar-toggler navbar-toggler-right collapsed " : "navbar-toggler navbar-toggler-right ";

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let navbar = document.getElementById("navbar");
    let logo = document.getElementById("logo");

    document.addEventListener("scroll", () => {
      if (window.scrollY > 5) {
        logo.src = window.innerWidth < 310 ? "/img/small logo.png" : "/img/navbar logo.png";
        navbar.classList.add("is-sticky");
      } else {
        if (window.innerWidth > 991) {
          logo.src = "/img/logo.png";
        }
        navbar.classList.remove("is-sticky");
      }
    });

    window.addEventListener("resize", () => {
      if (window.innerWidth > 991 && window.scrollY <=5) {
        logo.src = "/img/logo.png";
      } else if (window.innerWidth < 310) {
        logo.src = "/img/small logo.png";
      } else {
        logo.src = "/img/navbar logo.png";
      }
    });
  });

  return (
    <>
      <header className="header-area fixed-top">
        <TopHeader />

        <div className="nav-area">
          <div id="navbar" className="navbar-area">
            <div className="main-nav">
              <nav className="navbar navbar-expand-md navbar-light">
                <div className="container ">
                  <Link to="/" className="navbar-brand">
                    <img
                      id="logo"
                      src={window.innerWidth > 991 ? "/img/logo.png" : window.innerWidth < 310 ? "/img/small logo.png" : "/img/navbar logo.png"}
                      alt="logo"
                    />
                  </Link>

                  <button
                    className={classTwo}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleNavbar}
                  >
                    <span className="icon-bar top-bar"></span>
                    <span className="icon-bar middle-bar"></span>
                    <span className="icon-bar bottom-bar"></span>
                  </button>

                  <div className={classOne} id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto">
                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="#"
                          className="nav-link"
                          onClick={toggleNavbar}
                        >
                          Naslovna
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="#usluge"
                          className="nav-link"
                          onClick={toggleNavbar}
                        >
                          Usluge
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="#galerija"
                          className="nav-link"
                          onClick={toggleNavbar}
                        >
                          Galerija
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="#rezervacije"
                          className="nav-link"
                          onClick={toggleNavbar}
                        >
                          Rezervacije
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="#informacije"
                          className="nav-link"
                          onClick={toggleNavbar}
                        >
                          Informacije
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
