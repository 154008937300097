import React from "react";

const Facilities = () => {
  return (
    <>
      <div className="second-facility-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div 
              className="col-lg-4 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <div className="second-facility-item">
                <img src="/img/facilities/facilities (1).png" alt="Our Doctors" width="512" height="512" />
                <h3>Stručni Tim</h3>
                <p>
                  Sastavljen od iskusnih medicinskih profesionalaca, naš tim je 
                  osmišljen kako bi zadovoljio sve potrebe naših pacijenata. 
                  Radimo kao jedan, fokusirani na pružanje nege koja prevazilazi 
                  očekivanja.
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <div className="second-facility-item">
                <img src="/img/facilities/facilities (2).png" alt="Our Vehicles" width="512" height="512" />
                <h3>Ambulanta Vozila</h3>
                <p>
                  Bez obzira da li je reč o sanitetskom prevozu ili prilagođenom 
                  vozilu za specifične medicinske potrebe, naš vozni park je deo 
                  naše posvećenosti pružanju vrhunskih mobilnih zdravstvenih usluga.
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              <div className="second-facility-item">
                <img src="/img/facilities/facilities (3).png" alt="Our Technology" width="512" height="512" />
                <h3>Dijagnostika i Tehnologija</h3>
                <p> 
                  Aktivno pratimo najnovije trendove i implementiramo inovacije 
                  kako bismo obezbedili najbolju moguću zdravstvenu negu, 
                  prilagođenu savremenim potrebama pacijenata.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
