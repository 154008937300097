import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

const Gallery = () => {
  return (
    <>
      <div id="galerija" className="our-work-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span className="top-title">Galerija</span>
            <h2>Medicinski Prostor</h2>
            <p>
              Bilo da je reč o čekaonici, recepciji ili ambulanti, svaki kutak 
              Hera Medicala je pažljivo osmišljen kako bi pružio osećaj topline 
              i podrške.
            </p>
          </div>

          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 2
              },
              992: {
                slidesPerView: 3
              }
            }}
            modules={[Pagination, Autoplay]}
            className="our-work-slide"
          >
            <SwiperSlide>
              <img src="/img/gallery/photo (1).webp" alt="Premises" width="570" height="400" />
            </SwiperSlide>

            <SwiperSlide>
              <img src="/img/gallery/photo (2).webp" alt="Premises" width="570" height="400" />
            </SwiperSlide>

            <SwiperSlide>
              <img src="/img/gallery/photo (3).webp" alt="Premises" width="570" height="400" />
            </SwiperSlide>

            <SwiperSlide>
              <img src="/img/gallery/photo (4).webp" alt="Premises" width="570" height="400" />
            </SwiperSlide>

            <SwiperSlide>
              <img src="/img/gallery/photo (5).webp" alt="Premises" width="570" height="400" />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="shape">
          <img src="/img/shape/shape (2).webp" alt="Shape" width="480" height="520" />
        </div>
      </div>
    </>
  );
};

export default Gallery;
